import React from "react";
import { Hero } from "@hyperobjekt/material-ui-website";
import { withStyles } from "@material-ui/core";
import { FONTS } from "../theme";

const styles = (theme) => ({
  root: {
    minHeight: "auto !important",
    paddingTop: "4rem !important",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "6rem !important",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "8rem !important",
    },
  },
});

const MovieHero = ({ classes, ...props }) => {
  const { root, container } = classes;
  return <Hero bgcolor="background.dark" classes={{ root }} {...props} />;
};

export default withStyles(styles)(MovieHero);
