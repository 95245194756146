import React from "react";
import clsx from "clsx";
import { GatsbyLink } from "gatsby-material-ui-components";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  logo: {
    marginTop: 6,
  },
  light: {
    "& .logo__dark": {
      fill: "#FBF9F6",
    },
    "& .logo__light": {
      fill: "#1A1716",
    },
  },
});

const Logo = ({ classes, light }) => {
  return (
    <GatsbyLink to="/">
      <svg
        aria-label="Debt Collection Lab"
        role="img"
        width="230"
        height="21"
        viewBox="0 0 230 21"
        fill="none"
        className={clsx("logo", classes.logo, { [classes.light]: light })}
      >
        <rect
          y="-0.160156"
          width="62.1457"
          height="21.6291"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M16.8172 11.9297V8.73275C16.8172 6.47508 15.4824 4.79419 12.549 4.79419H8.16553V15.7694H12.549C15.4824 15.7694 16.8172 14.1874 16.8172 11.9297ZM14.164 11.9133C14.164 13.0668 13.5543 13.6601 12.3513 13.6601H10.8022V6.90354H12.3513C13.5543 6.90354 14.164 7.4968 14.164 8.65036V11.9133Z"
          fill="#FBF9F6"
          className="logo__light"
        />
        <path
          d="M28.8467 15.7694V13.5612H24.0842V10.9575H27.413V9.24361H24.0842V6.92003H28.8467V4.79419H21.4475V15.7694H28.8467Z"
          fill="#FBF9F6"
          className="logo__light"
        />
        <path
          d="M41.5192 13.1327V12.3088C41.5192 11.3035 40.9919 10.4466 39.657 10.1829C40.893 9.9687 41.4203 9.0953 41.4203 8.04062V7.43088C41.4203 5.81591 40.2997 4.79419 38.2563 4.79419H33.3455V15.7694H38.3222C40.3657 15.7694 41.5192 14.7642 41.5192 13.1327ZM38.899 8.46908C38.899 9.12826 38.52 9.4908 37.7454 9.4908H35.9986V6.78819H37.8278C38.6024 6.78819 38.899 7.16722 38.899 7.74399V8.46908ZM38.932 12.8526C38.932 13.4129 38.6353 13.7919 37.8608 13.7919H35.9986V11.0728H37.7784C38.5529 11.0728 38.932 11.4189 38.932 12.0945V12.8526Z"
          fill="#FBF9F6"
          className="logo__light"
        />
        <path
          d="M54.0057 6.95298V4.79419H45.5188V6.95298H48.4356V15.7694H51.0723V6.95298H54.0057Z"
          fill="#FBF9F6"
          className="logo__light"
        />
        <path
          d="M78.7704 8.88009V8.0726C78.7704 6.0786 77.2708 4.62842 74.6836 4.62842C71.948 4.62842 70.2671 6.37523 70.2671 8.64938V12.1924C70.2671 14.4666 71.8821 16.2134 74.6836 16.2134C77.139 16.2134 78.7375 14.7962 78.7375 12.8022V11.9288H76.2985V12.6374C76.2985 13.5437 75.7547 14.0876 74.7495 14.0876C73.6124 14.0876 72.9367 13.4119 72.9367 12.3902V8.50106C72.9367 7.41343 73.563 6.73777 74.733 6.73777C75.7382 6.73777 76.3315 7.24863 76.3315 8.23739V8.88009H78.7704Z"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M91.8674 12.2089V8.61642C91.8674 6.29283 90.1865 4.62842 87.4509 4.62842C84.7154 4.62842 83.018 6.29283 83.018 8.61642V12.2089C83.018 14.516 84.7154 16.2134 87.4509 16.2134C90.1865 16.2134 91.8674 14.516 91.8674 12.2089ZM89.1977 12.3408C89.1977 13.3295 88.6045 14.0876 87.4509 14.0876C86.2809 14.0876 85.7041 13.3295 85.7041 12.3408V8.50106C85.7041 7.49582 86.2809 6.73777 87.4509 6.73777C88.6045 6.73777 89.1977 7.49582 89.1977 8.50106V12.3408Z"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M103.898 15.9003V13.7085H99.1356V4.92505H96.4989V15.9003H103.898Z"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M115.522 15.9003V13.7085H110.76V4.92505H108.123V15.9003H115.522Z"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M127.147 15.9003V13.6921H122.384V11.0883H125.713V9.37447H122.384V7.05088H127.147V4.92505H119.748V15.9003H127.147Z"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M139.853 8.88009V8.0726C139.853 6.0786 138.354 4.62842 135.767 4.62842C133.031 4.62842 131.35 6.37523 131.35 8.64938V12.1924C131.35 14.4666 132.965 16.2134 135.767 16.2134C138.222 16.2134 139.82 14.7962 139.82 12.8022V11.9288H137.382V12.6374C137.382 13.5437 136.838 14.0876 135.832 14.0876C134.695 14.0876 134.02 13.4119 134.02 12.3902V8.50106C134.02 7.41343 134.646 6.73777 135.816 6.73777C136.821 6.73777 137.414 7.24863 137.414 8.23739V8.88009H139.853Z"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M152.225 7.08384V4.92505H143.738V7.08384H146.655V15.9003H149.292V7.08384H152.225Z"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M159.124 15.9003V4.92505H156.488V15.9003H159.124Z"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M172.626 12.2089V8.61642C172.626 6.29283 170.946 4.62842 168.21 4.62842C165.474 4.62842 163.777 6.29283 163.777 8.61642V12.2089C163.777 14.516 165.474 16.2134 168.21 16.2134C170.946 16.2134 172.626 14.516 172.626 12.2089ZM169.957 12.3408C169.957 13.3295 169.364 14.0876 168.21 14.0876C167.04 14.0876 166.463 13.3295 166.463 12.3408V8.50106C166.463 7.49582 167.04 6.73777 168.21 6.73777C169.364 6.73777 169.957 7.49582 169.957 8.50106V12.3408Z"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M185.976 15.9003V4.92505H183.537V11.2531L182.696 9.88533L179.499 4.92505H177.258V15.9003H179.697V9.63814L180.587 11.0224L183.866 15.9003H185.976Z"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M204.671 15.9003V13.7085H199.909V4.92505H197.272V15.9003H204.671Z"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M217.563 15.9003L214.251 4.92505H211.367L208.071 15.9003H210.526L211.169 13.6921H214.234L214.86 15.9003H217.563ZM213.773 11.8793H211.663L212.735 7.90781L213.773 11.8793Z"
          fill="#1A1716"
          className="logo__dark"
        />
        <path
          d="M229.757 13.2636V12.4396C229.757 11.4344 229.229 10.5775 227.894 10.3138C229.13 10.0996 229.658 9.22616 229.658 8.17148V7.56174C229.658 5.94677 228.537 4.92505 226.494 4.92505H221.583V15.9003H226.56C228.603 15.9003 229.757 14.895 229.757 13.2636ZM227.136 8.59994C227.136 9.25911 226.757 9.62166 225.983 9.62166H224.236V6.91905H226.065C226.84 6.91905 227.136 7.29807 227.136 7.87485V8.59994ZM227.169 12.9834C227.169 13.5437 226.873 13.9228 226.098 13.9228H224.236V11.2037H226.016C226.79 11.2037 227.169 11.5497 227.169 12.2254V12.9834Z"
          fill="#1A1716"
          className="logo__dark"
        />
      </svg>
    </GatsbyLink>
  );
};

export default withStyles(styles)(Logo);
