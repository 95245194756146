import { ArtsHero, DocumentaryFilm, CollectorSeries } from "arts-and-storytelling";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import * as React from 'react';
export default {
  ArtsHero,
  DocumentaryFilm,
  CollectorSeries,
  getImage,
  GatsbyImage,
  React
};