import React from "react";
import { Block } from "@hyperobjekt/material-ui-website";
import { Box, withStyles } from "@material-ui/core";
import { Button } from "gatsby-theme-material-ui";
import { FONTS } from "../theme";
import Typography from "../components/typography";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(15, 0, 15),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(12, 0, 10),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(12, 0, 15),
    },
  },
  container: {
    justifyContent: "flex-start",
    gap: theme.typography.pxToRem(90),
    [theme.breakpoints.up("md")]: {
      gap: theme.typography.pxToRem(88),
    },
  },
  btnSeeFilm: {
    marginRight: "auto",
    marginTop: "16px",
    border: "2px solid green",
  },
  title: {
    position: "relative",
    ...FONTS.KNOCKOUT.FullMiddleweight,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(60),
    lineHeight: "60px" /* 75% */,
    letterSpacing: "3.2px",
    marginBottom: `0.25em`,
    "&:after": {
      content: "''",
      position: "absolute",
      bottom: `-1em`,
      left: 0,
      width: `1.75em`,
      height: `0.5em`,
      backgroundImage: `url(/images/underline.svg)`,
      backgroundSize: `contain`,
      backgroundRepeat: `no-repeat`,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(80),
    },
  },
  intro: {
    display: "flex",
    flexDirection: "column",
    gap: 71,
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",

    "& > p + p": {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      "& > p": {
        maxWidth: "30em",
      },
    },
  },
  video: {
    aspectRatio: 16 / 9,
    width: "100%",
    flex: "0 0 60%",
    backgroundColor: "transparent",
  },
  caption: {
    marginTop: theme.spacing(1),
    fontStyle: "italic",
    textAlign: "center",
  },
  eyebrow: {
    color: theme.palette.primary.main,
    ...FONTS.KNOCKOUT.Lightweight,
    fontSize: "20px",
    lineHeight: "130%" /* 26px */,
    letterSpacing: "2px",
    textTransform: "uppercase",
  },
});

const DocumentaryFilm = ({ classes, ...props }) => {
  const { root, container } = classes;

  return (
    <Block bgcolor="background.paper" classes={{ root, container }} {...props}>
      <Box>
        <Typography className={classes.eyebrow}>Documentary Film</Typography>
        <h2 className={classes.title}>Shame On You!</h2>
      </Box>
      <Box className={classes.intro}>
        <Box className={classes.video}>
          <iframe
            title="Shame On You!"
            src="https://player.vimeo.com/video/872013158?h=f09ed6e8ab"
            style={{
              border: 0,
              width: "100%",
              height: "100%",
            }}
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
          <h2 className={classes.caption}>Official Trailer</h2>
        </Box>

        <Box className={classes.content}>
          <Box>
            <Typography>
              Shame On You! is a short animated documentary that plunges into
              the visual data presented at the Debt Collection Lab. In
              recreating several personal stories of people trapped in debt
              collection lawsuits, the film shows that it is because of hidden
              shame about debt that their stories are untold, which helps
              explain why 90% of the cases in the data are either unrepresented
              in courts or unopposed. The film exposes how the debt collection
              industry is enabled by shame as well as fear of the judicial
              system.
            </Typography>
            <Button
              href="/arts-and-storytelling/shame-on-you"
              style={{
                marginRight: "auto",
                marginTop: 28,
              }}
              variant="contained"
              color="primary"
            >
              See the full film
            </Button>
          </Box>
        </Box>
      </Box>
    </Block>
  );
};

export default withStyles(styles)(DocumentaryFilm);
