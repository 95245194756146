// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-hypercore-src-templates-page-js": () => import("./../../../node_modules/gatsby-theme-hypercore/src/templates/page.js" /* webpackChunkName: "component---node-modules-gatsby-theme-hypercore-src-templates-page-js" */),
  "component---src-about-us-layout-js": () => import("./../../../src/about-us/layout.js" /* webpackChunkName: "component---src-about-us-layout-js" */),
  "component---src-arts-and-storytelling-layout-js": () => import("./../../../src/arts-and-storytelling/layout.js" /* webpackChunkName: "component---src-arts-and-storytelling-layout-js" */),
  "component---src-get-the-data-layout-js": () => import("./../../../src/get-the-data/layout.js" /* webpackChunkName: "component---src-get-the-data-layout-js" */),
  "component---src-lawsuit-tracker-layouts-county-layout-js": () => import("./../../../src/lawsuit-tracker/layouts/county/layout.js" /* webpackChunkName: "component---src-lawsuit-tracker-layouts-county-layout-js" */),
  "component---src-lawsuit-tracker-layouts-index-layout-js": () => import("./../../../src/lawsuit-tracker/layouts/index/layout.js" /* webpackChunkName: "component---src-lawsuit-tracker-layouts-index-layout-js" */),
  "component---src-lawsuit-tracker-layouts-state-layout-js": () => import("./../../../src/lawsuit-tracker/layouts/state/layout.js" /* webpackChunkName: "component---src-lawsuit-tracker-layouts-state-layout-js" */),
  "component---src-lawsuit-tracker-layouts-tract-layout-js": () => import("./../../../src/lawsuit-tracker/layouts/tract/layout.js" /* webpackChunkName: "component---src-lawsuit-tracker-layouts-tract-layout-js" */),
  "component---src-research-index-layout-js": () => import("./../../../src/research/index/layout.js" /* webpackChunkName: "component---src-research-index-layout-js" */),
  "component---src-research-paper-layout-js": () => import("./../../../src/research/paper/layout.js" /* webpackChunkName: "component---src-research-paper-layout-js" */)
}

