import { Banner, HomeHero, StatHero, TextHero, TrackerCallout, ResearchCallout, ArtsCallout, AboutUsCallout } from "home";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { GatsbyLink } from "gatsby-theme-material-ui";
import * as React from 'react';
export default {
  Banner,
  HomeHero,
  StatHero,
  TextHero,
  TrackerCallout,
  ResearchCallout,
  ArtsCallout,
  AboutUsCallout,
  getImage,
  GatsbyImage,
  GatsbyLink,
  React
};