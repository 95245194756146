import React from "react";
import Callout from "../components/sections/callout";
import { withStyles } from "@material-ui/core";
import {
  COLORS,
  FONTS,
 } from "../theme";
const styles = (theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(4, 4, 10),
    },
  },
  container: {
    position: "relative",
    padding: theme.spacing(6, 4),
    background: COLORS.altBackground,
    color: COLORS.text,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(14, 8),
    },
    "& .research-callout-contents": {
      position: "relative",
      zIndex: 2,
    },
    "& h2": {
      ...FONTS.KNOCKOUT["Lightweight"],
      fontSize: theme.typography.pxToRem(36),
      lineHeight: 1,
      letterSpacing: "0.04em",
      color: COLORS.primary,
      [theme.breakpoints.up("sm")]: {
        fontSize: theme.typography.pxToRem(48),
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.pxToRem(60),
      },
    },
    "& .research-item": {
      color: COLORS.text,
      "& .author": {
        color: COLORS.burntMaroon,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(19),
        [theme.breakpoints.up("sm")]: {
          fontSize: theme.typography.pxToRem(16),
          lineHeight: theme.typography.pxToRem(21),
        },
      },
      "& .title": {
        marginTop: theme.spacing(1),
        ...FONTS.KNOCKOUT["Middleweight"],
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(26),
        [theme.breakpoints.up("sm")]: {
          fontSize: theme.typography.pxToRem(20),
          lineHeight: theme.typography.pxToRem(28),
        },
      },
    },
    "& .research-cta-wrapper": {
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-start",
      },
    },
    "& .research-bg": {
      position: "absolute",
      inset: 0,
      zIndex: 1,
      "& img": {
        position: "absolute",
        top: "auto",
        bottom: 0,
        left: 0,
        right: 0,
        maxHeight: "200px",
        zIndex: 1,
      },
    },
  },
});

const ResearchCallout = (props) => {
  return <Callout {...props} />;
};

ResearchCallout.defaultProps = {};

ResearchCallout.propTypes = {};

export default withStyles(styles)(ResearchCallout);
