import React from "react";
import {
  Box,
  withStyles,
} from "@material-ui/core";
import { COLORS } from "../theme";

const styles = (theme) => ({
  root: {
    marginTop: `calc(-1 * ${theme.spacing(8)})`,
    "& .banner-contents": {
      backgroundColor: COLORS.burntMaroon,
      color: "white",
      padding: theme.spacing(2),
      textAlign: "center",
      "& a": {
        color: "inherit",
        textDecoration: "underline",
        "&:hover": {
          textDecoration: "none",
        }
      }
    },
  },
});

const Banner = (props) => {
  return (
    <Box
      bgcolor="background.alt"
      {...props}
    />
  );
};

export default withStyles(styles)(Banner);
