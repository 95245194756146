import React from "react";
import { useSnapCarousel } from "react-snap-carousel";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { Button } from "gatsby-theme-material-ui";

const styles = {
  root: {
    width: "100%",
    height: "2rem",
    display: "flex",
    position: "relative",
  },
  scroll: {
    position: "relative",
    display: "flex",
    overflow: "hidden", // prevents mouse scrolling
    scrollSnapType: "x mandatory",
    gap: "0.5rem",
    margin: 0,
    padding: 0,
  },
  item: {
    flexShrink: 0,
    listStyleType: "none",
  },
  itemSnapPoint: {
    scrollSnapAlign: "start",
  },
  nextPrevButton: {
    height: "2rem",
    width: "2rem",
    flex: "0 0 2rem",
    minWidth: "auto",
  },
  nextPrevButtonDisabled: { opacity: 0.3 },
  pagination: {
    display: "flex",
  },
  paginationButton: {
    margin: "10px",
  },
  paginationButtonActive: { opacity: 0.3 },
  pageIndicator: {
    display: "flex",
    justifyContent: "center",
  },
};

const GradiantOverlay = ({ direction = "left", visible }) => {
  const right = `linear-gradient(90deg, rgba(255,255,255,0) 90%, rgba(255,255,255,1) 100%)`;
  const left = `linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 10%, rgba(255,255,255,0) 100%`;

  return (
    <div
      style={{
        width: "calc(100% - 4rem)",
        height: "100%",
        [direction]: "2rem",
        position: "absolute",
        opacity: visible ? 1 : 0,
        zIndex: 1,
        transition: `opacity 0.3s ease`,
        pointerEvents: "none",
        background: direction === "left" ? left : right,
      }}
    />
  );
};

export const Carousel = ({ items, renderItem }) => {
  const {
    scrollRef,
    pages,
    activePageIndex,
    hasPrevPage,
    hasNextPage,
    prev,
    next,
    goTo,
    snapPointIndexes,
  } = useSnapCarousel();

  return (
    <div style={styles.root}>
      {pages.length > 1 && (
        <Button
          variant="outlined"
          color="secondary"
          style={{
            ...styles.nextPrevButton,
            ...(!hasPrevPage ? styles.nextPrevButtonDisabled : {}),
          }}
          onClick={() => prev()}
          disabled={!hasPrevPage}
        >
          <ChevronLeft />
        </Button>
      )}
      <GradiantOverlay direction="left" visible={hasPrevPage} />
      <ul
        style={{
          ...styles.scroll,
        }}
        ref={scrollRef}
      >
        {items.map((item, i) =>
          renderItem({
            item,
            isSnapPoint: snapPointIndexes.has(i),
          })
        )}
      </ul>
      <GradiantOverlay direction="right" visible={hasNextPage} />
      {pages.length > 1 && (
        <Button
          variant="outlined"
          color="secondary"
          style={{
            ...styles.nextPrevButton,
            ...(!hasNextPage ? styles.nextPrevButtonDisabled : {}),
          }}
          onClick={() => next()}
          disabled={!hasNextPage}
        >
          <ChevronRight />
        </Button>
      )}
    </div>
  );
};

export const CarouselItem = ({ isSnapPoint, children }) => (
  <li
    style={{
      ...styles.item,
      ...(isSnapPoint ? styles.itemSnapPoint : {}),
    }}
  >
    {children}
  </li>
);
