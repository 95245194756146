import React from "react";
import { Hero } from "@hyperobjekt/material-ui-website";
import { Typography, withStyles } from "@material-ui/core";
import * as d3 from "d3";
import { FONTS } from "../../../theme";
import DownloadButton from "./downloadButton";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(12, 0, 10),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(15, 0, 12),
    },
    [theme.breakpoints.up("md")]: {
      padding: "10.5rem 0 6rem !important",
    },

    "& .download-button": {
      marginTop: theme.spacing(1),
    },
  },
  title: {
    maxWidth: 726,
    zIndex: 2,
    letterSpacing: "0.02em",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(36),
    lineHeight: 70 / 60,
    ...FONTS.KNOCKOUT["Lightweight"],
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(48),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.typography.pxToRem(60),
    },
    marginBottom: theme.spacing(7),
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      bottom: `-0.5em`,
      left: 0,
      width: `4em`,
      height: `0.5em`,
      transform: `translateY(${theme.spacing(2)})`,
      backgroundImage: `url(/images/underline.svg)`,
      backgroundSize: `4em 11px`,
      backgroundRepeat: `no-repeat`,
    },
  },
  details: {
    color: theme.palette.text.secondaryDarkBg,
  },
  container: {
    justifyContent: "flex-start",
  },
});

const ResearchPaperHero = ({ classes, downloadButtonProps, ...props }) => {
  const { root, container } = classes;

  const { title, authors, date, filePath } = props?.meta || {};
  return (
    <Hero bgcolor="background.dark" classes={{ root, container }}>
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.details} variant="body2">
        {new Intl.ListFormat().format(authors)} •{" "}
        {d3.timeFormat("%B %d, %Y")(new Date(date))}
      </Typography>
      <DownloadButton path={filePath} {...downloadButtonProps} />
    </Hero>
  );
};

export default withStyles(styles)(ResearchPaperHero);
