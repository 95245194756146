import { COUNTIES, STATES } from "../scripts/consts";
/**
 * Gets a nested object property from the source object
 * @param {*} obj
 * @param {*} path
 */
export const getObjectProperty = (source, path) => {
  const keys = path.split(".");
  return keys.reduce((result, key) => {
    return result && typeof result === "object" ? result["key"] : null;
  }, source);
};

export const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

export function getTractNameForFips(fips) {
  return "Census Tract " + Number(fips.slice(-5)) / 100;
}

export function getCountyNameForFips(fips) {
  if (!fips || fips.length < 2) return null;
  const countyFips = fips.substr(0, 5);
  if (COUNTIES[countyFips]) {
    const ending =
      COUNTIES.altEnding[countyFips] !== undefined
        ? COUNTIES.altEnding[countyFips]
        : " County";
    return COUNTIES[countyFips] + ending;
  }
  return null;
}

export function getStateNameForFips(fips) {
  if (!fips || fips.length < 2) return null;
  const stateFips = fips.substr(0, 2);
  if (STATES[stateFips]) return STATES[stateFips].full;
  return null;
}
