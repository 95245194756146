import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import CloseIcon from "@material-ui/icons/Close";
import {
  withStyles,
  Typography,
  Box,
  Link,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Chip,
} from "@material-ui/core";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as d3 from "d3";
import { Block } from "@hyperobjekt/material-ui-website";
import { uniq } from "lodash";
import { COLORS, FONTS } from "../../../theme";
import DownloadButton from "../../paper/components/downloadButton";
import { Carousel, CarouselItem } from "../../../components/carousel";

const styles = (theme) => ({
  root: {
    // TODO - remove once we have multiple posts
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(20),
    },
    "& .download-button": {
      marginTop: theme.spacing(0.5),
    },
    "& .research-posts": {
      padding: theme.spacing(8, 0),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(10, 0),
      },
    },
  },
  inputLabel: {
    display: "flex !important",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: "1rem",
    color: "#797267 !important",
  },
  select: {
    width: "100%",
  },
  postRow: {
    "&:nth-child(even)": {
      background: COLORS.paper,
    },
    "& h3": {
      fontSize: "23px",
      [theme.breakpoints.up("md")]: {
        marginLeft: "unset",
      },
    },
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
});

const StyledBlock = withStyles((theme) => ({
  root: {
    position: "relative",
    padding: theme.spacing(9, 0),
    "& img": {
      maxWidth: "100%",
    },
  },
}))(Block);

const FilterDot = withStyles((theme) => ({
  root: {
    height: "1rem",
    width: "1rem",
    borderRadius: "50%",
    display: "inline-block",
    marginBottom: 2,
  },
}))(Box);

const PostList = ({ classes, ...props }) => {
  // TODO JJ - could avoid using useStaticQuery by instead passing down this data
  // from the ResearchIndexQuery
  const data = useStaticQuery(graphql`
    query PostListQuery {
      allMdx(
        filter: { frontmatter: { meta: { isBlogPost: { eq: true } } } }
        sort: { order: DESC, fields: frontmatter___meta___date }
      ) {
        nodes {
          frontmatter {
            draft
            path
            meta {
              authors
              locations
              issues
              date
              description
              filePath
              keywords
              title
              image {
                childImageSharp {
                  gatsbyImageData(
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                    width: 270
                    height: 228
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const location = useLocation();

  const [filters, setFilters] = React.useState({
    locations: [],
    issues: [],
    authors: [],
  });

  // MUI issue that causes footer css to not load correctly when page is loaded with query params.
  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const locations = search.getAll("locations").map(decodeURIComponent);
    const issues = search.getAll("issues").map(decodeURIComponent);
    const authors = search.getAll("authors").map(decodeURIComponent);

    setFilters({ locations, issues, authors });
  }, []);

  useEffect(() => {
    const url = new URL(location.origin + location.pathname);

    filters.locations.forEach((x) =>
      url.searchParams.append("locations", encodeURIComponent(x))
    );
    filters.issues.forEach((x) =>
      url.searchParams.append("issues", encodeURIComponent(x))
    );
    filters.authors.forEach((x) =>
      url.searchParams.append("authors", encodeURIComponent(x))
    );

    history.replaceState({}, "", url);
  }, [filters, location.origin, location.pathname]);

  const posts = data?.allMdx?.nodes;

  const filteredPosts = posts.filter(
    ({
      frontmatter: {
        meta: { locations, issues, authors },
      },
    }) =>
      (filters.locations.length === 0 ||
        filters.locations.some((x) => locations.includes(x))) &&
      (filters.issues.length === 0 ||
        filters.issues.some((x) => issues.includes(x))) &&
      (filters.authors.length === 0 ||
        filters.authors.some((x) => authors.includes(x)))
  );

  const getPostRow = (post) => {
    const { image, title, authors, date, filePath, description } =
      post.frontmatter.meta;

    return (
      <StyledBlock className={[classes.postRow, "research-posts"]}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={3}>
            <GatsbyImage image={getImage(image)} />
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography color="secondary" variant="body2">
              {new Intl.ListFormat().format(authors)} •{" "}
              {d3.timeFormat("%B %e, %Y")(new Date(date))}
            </Typography>
            <Link
              href={post.frontmatter.path}
              style={{ color: COLORS.secondary }}
            >
              <Typography
                className={classes.title}
                color="secondary"
                variant="h5"
                component="h3"
              >
                {title}
              </Typography>
            </Link>
            <DownloadButton path={filePath} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography className={"description"} variant="body2">
              {description}
            </Typography>
          </Grid>
        </Grid>
      </StyledBlock>
    );
  };

  const SelectFilter = ({
    id,
    label,
    options,
    value,
    onChange,
    placeholder,
  }) => {
    const emptyValue = {
      location: "states",
      issue: "issues",
      author: "authors",
    }[id];

    return (
      <Box style={{ flex: 1 }}>
        <InputLabel className={classes.inputLabel} htmlFor={id}>
          <FilterDot bgcolor={getColorByFilterId(id)} />
          {label}
        </InputLabel>
        <Select
          className={classes.select}
          id={id}
          value={null}
          onChange={(e) => onChange(e.target.value)}
          variant="outlined"
          disableUnderline
          displayEmpty
          renderValue={(selected) =>
            selected ?? (
              <span
                style={{
                  ...FONTS.MAPLE["Regular"],
                  fontSize: 14,
                }}
              >
                {placeholder}
              </span>
            )
          }
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          {options.length === 0 && (
            <MenuItem style={{ fontStyle: "italic" }} value={""} disabled>
              No {emptyValue} available
            </MenuItem>
          )}
        </Select>
      </Box>
    );
  };

  const getColorByFilterId = (id) =>
    ({
      location: "#EFDDC5",
      issue: "#EEEEE6",
      author: "#E3E7F1",
    }[id]);

  const Filters = ({ filters, setFilters }) => {
    const locations = uniq(
      posts.flatMap((post) => post.frontmatter.meta.locations)
    );
    const issues = uniq(posts.flatMap((post) => post.frontmatter.meta.issues));
    const authors = uniq(
      posts.flatMap((post) => post.frontmatter.meta.authors)
    ).filter((author) =>
      [
        "Hannah Hill",
        "Claire Johnson Raba",
        "Dalié Jiménez",
        "Divia Kallattil",
        "David McClendon",
        "Frederick F. Wherry",
      ].includes(author)
    );

    const locationOptions = locations
      .filter((location) => !filters.locations.includes(location))
      .map((location) => ({
        label: location,
        value: location,
      }));
    const issueOptions = issues
      .filter((issue) => !filters.issues.includes(issue))
      .map((issue) => ({
        label: issue,
        value: issue,
      }));
    const authorOptions = authors
      .filter((author) => !filters.authors.includes(author))
      .map((author) => ({
        label: author,
        value: author,
      }));

    return (
      <Block>
        <Typography
          variant="h2"
          style={{ fontSize: 34, marginBottom: "1.5rem" }}
        >
          Filter results
        </Typography>
        <Box
          style={{
            display: "flex",
            gap: "2rem",
            flexWrap: "wrap",
            maxWidth: "80%",
            width: "100%",
          }}
        >
          <SelectFilter
            id="location"
            label="State"
            options={locationOptions}
            value={filters.locations}
            onChange={(location) =>
              setFilters((x) => ({
                ...x,
                locations: [...x.locations, location],
              }))
            }
            placeholder="Select a state"
          />
          <SelectFilter
            id="issue"
            label="Issue"
            options={issueOptions}
            value={filters.issues}
            onChange={(issue) =>
              setFilters((x) => ({ ...x, issues: [...x.issues, issue] }))
            }
            placeholder="Select an issue"
          />
          <SelectFilter
            id="author"
            label={"DCL Author"}
            options={authorOptions}
            value={filters.authors}
            onChange={(author) =>
              setFilters((x) => ({ ...x, authors: [...x.authors, author] }))
            }
            placeholder="Select an author"
          />
        </Box>
        <Box style={{ paddingTop: "2rem" }}>
          <Carousel
            items={[
              ...filters.locations.map((value) => ({
                value,
                color: getColorByFilterId("location"),
              })),
              ...filters.issues.map((value) => ({
                value,
                color: getColorByFilterId("issue"),
              })),
              ...filters.authors.map((value) => ({
                value,
                color: getColorByFilterId("author"),
              })),
            ]}
            renderItem={({ item, isSnapPoint }) => (
              <CarouselItem key={item.value} isSnapPoint={isSnapPoint}>
                <Chip
                  style={{ background: item.color, borderRadius: 0 }}
                  key={item.value}
                  deleteIcon={<CloseIcon />}
                  bgcolor={item.color}
                  className={classes.chip}
                  onDelete={() =>
                    setFilters((x) => ({
                      locations: x.locations.filter((x) => x !== item.value),
                      issues: x.issues.filter((x) => x !== item.value),
                      authors: x.authors.filter((x) => x !== item.value),
                    }))
                  }
                  label={item.value}
                />
              </CarouselItem>
            )}
          />
        </Box>
      </Block>
    );
  };

  return (
    <Box className={classes.root}>
      <Filters filters={filters} setFilters={setFilters} />
      {filteredPosts.map(getPostRow)}
    </Box>
  );
};

export default withStyles(styles)(PostList);
