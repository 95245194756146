import React from "react";
import clsx from "clsx";
import { Typography, withStyles, Button, Box } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    "& .MuiTypography-root": {
      display: "inline",
      fontWeight: 500,
    },
    "&:not(.withLabel) a": {
      paddingLeft: 0,
    },
    "& img": {
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },
  },
});

const DownloadButton = ({
  path,
  classes,
  label,
  buttonText = "Download PDF",
}) => {
  if (!path) return null;
  return (
    <Box
      className={clsx("download-button", classes.root, {
        withLabel: !!label,
      })}
    >
      {!!label && <Typography color="primary">{label}</Typography>}
      <Button href={path} download disableRipple target="_blank">
        {buttonText}
        <img src="/images/download.svg" alt="" />
      </Button>
    </Box>
  );
};

export default withStyles(styles)(DownloadButton);
